var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{staticClass:"payment-breakdown-section"},[_c('h1',{staticClass:"payment-breakdown-section__title"},[_vm._v("\n      "+_vm._s(_vm.$t("batch_transfer_manager_module.batch_total"))+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"payment-breakdown-row highlighted"},[_c('p',{staticClass:"success--text"},[_vm._v(_vm._s(_vm.$t("total_due")))]),_vm._v(" "),_c('p',{staticClass:"success--text"},[_vm._v(_vm._s(_vm._f("currency")(_vm.values.initial)))])])]),_vm._v(" "),_c('section',{staticClass:"payment-breakdown-section"},[_c('h1',{staticClass:"payment-breakdown-section__title"},[_vm._v("\n      "+_vm._s(_vm.$t("batch_transfer_manager_module.payment_methods_string"))+"\n    ")]),_vm._v(" "),_vm._l((_vm.values.payment_and_methods),function(method,index){return _c('div',{key:index,class:[
        'payment-breakdown-row',
        { highlighted: method.applied !== 0 }
      ]},[_c('p',[_c('v-icon',{staticClass:"mr-3",attrs:{"small":""},on:{"click":function($event){_vm.removePayment(index)}}},[_vm._v("fa fa-trash")]),_vm._v("\n        "+_vm._s(method.name)+"\n      ")],1),_vm._v(" "),_c('div',{staticClass:"payment-breakdown-row__form"},[_c('p',{staticClass:"mr-5"},[_c('b',{staticClass:"mr-4"},[_vm._v(_vm._s(_vm.$t("batch_transfer_manager_module.amount_applied")))]),_vm._v(" "),_c('span',{staticClass:"success--text"},[_vm._v(_vm._s(_vm._f("currency")(method.applied)))])]),_vm._v(" "),_c('p',[_c('b',{staticClass:"mr-4"},[_vm._v(_vm._s(_vm.$t("batch_transfer_manager_module.amount_remaining")))]),_vm._v(" "),_c('span',{staticClass:"success--text",class:{
              'error--text': _vm.outstanding > 0,
              'success--text': _vm.outstanding <= 0
            }},[_vm._v(_vm._s(_vm._f("currency")(method.remaining)))])])])])}),_vm._v(" "),(_vm.canAddPayments)?_c('v-form',{staticClass:"payment-breakdown__form"},[_c('div',{staticClass:"payment-breakdown__form__fields"},[_c('p',{staticClass:"payment-breakdown__form__field"},[_c('b',[_vm._v(_vm._s(_vm.$t("batch_transfer_manager_module.method")))]),_vm._v(" "),_c('v-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:{
              'v-input--with-errors': _vm.$validator.errors.has('payment.type')
            },attrs:{"items":_vm.validMethods,"item-text":"name","item-value":"id","name":"type","data-vv-scope":"payment"},model:{value:(_vm.editingModal.type),callback:function ($$v) {_vm.$set(_vm.editingModal, "type", $$v)},expression:"editingModal.type"}})],1),_vm._v(" "),_c('p',{staticClass:"payment-breakdown__form__field"},[_c('b',[_vm._v(_vm._s(_vm.$t("batch_transfer_manager_module.amount_applied")))]),_vm._v(" "),_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:('lessThanOutstanding'),expression:"'lessThanOutstanding'"}],class:{
              'v-input--with-errors': _vm.$validator.errors.has('payment.amount')
            },attrs:{"solo":"","type":"number","prefix":'$',"min":"0","name":"amount","disabled":!_vm.outstanding,"data-vv-scope":"payment"},model:{value:(_vm.editingModal.applied),callback:function ($$v) {_vm.$set(_vm.editingModal, "applied", $$v)},expression:"editingModal.applied"}})],1)]),_vm._v(" "),_c('div',{staticClass:"payment-breakdown__form__actions"},[_c('v-btn',{on:{"click":_vm.save}},[_vm._v(_vm._s(_vm.$t("batch_transfer_manager_module.labels.add")))])],1)]):_vm._e()],2),_vm._v(" "),_c('section',{staticClass:"payment-breakdown-section"},[_c('h1',{staticClass:"payment-breakdown-section__title"},[_vm._v("\n      "+_vm._s(_vm.$t("batch_transfer_manager_module.total_amounts"))+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"payment-breakdown-row"},[_c('p',[_vm._v(_vm._s(_vm.$t("batch_transfer_manager_module.total_amount_due")))]),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm._f("currency")(_vm.values.initial)))])]),_vm._v(" "),_c('div',{staticClass:"payment-breakdown-row"},[_c('p',[_vm._v(_vm._s(_vm.$t("batch_transfer_manager_module.total_amount_applied")))]),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm._f("currency")(_vm.totalApplied)))])]),_vm._v(" "),_c('div',{staticClass:"payment-breakdown-row"},[_c('p',[_vm._v("\n        "+_vm._s(_vm.$t("batch_transfer_manager_module.total_amount_outstanding"))+"\n      ")]),_vm._v(" "),_c('p',{class:{
          'error--text': _vm.outstanding > 0,
          'success--text': _vm.outstanding <= 0
        }},[_vm._v("\n        "+_vm._s(_vm._f("currency")(_vm.outstanding))+"\n      ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }