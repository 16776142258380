import { Component, Mixins, Prop, Watch } from "vue-property-decorator";

import cloneDeep from "lodash/cloneDeep";
import reduce from "lodash/reduce";
import { Getter } from "vuex-class";

import { currencyFilter } from "@/filters/currency.filter";
import { BatchTransfer } from "@/interfaces/batchTransferManager";
import { PaymentMethod } from "@/interfaces/retailSettings";
import { EventBus } from "@/internal";
import TransferValidators from "../../BatchTransferManager/TransferValidators.mixin";
import Template from "./PaymentBreakdown.template.vue";

const paymentBreakdownDefault: BatchTransfer.PaymentMethodsBreakdown = {
  initial: 0,
  payment_and_methods: [],
  outstanding: 0
};
@Component({
  mixins: [Template]
})
export default class PaymentBreakdown extends Mixins(TransferValidators) {
  public readonly defaultEditingModal: BatchTransfer.PaymentMethod = {
    type: -1,
    name: "",
    applied: 0,
    remaining: 0
  };
  @Getter("paymentMethods", { namespace: "AuthModule" })
  public methods!: PaymentMethod[];
  @Prop({ required: true })
  public breakdown!: BatchTransfer.PaymentMethodsBreakdown;
  @Prop({ default: false }) public editingTransfer!: boolean;
  public model: BatchTransfer.PaymentMethodsBreakdown = cloneDeep(
    paymentBreakdownDefault
  );

  public validMethods: PaymentMethod[] = [];

  public editingModal: BatchTransfer.PaymentMethod = cloneDeep(
    this.defaultEditingModal
  );

  /**
   * Watches for breakdown
   * @param {BatchTransfer.PaymentMethodsBreakdown} newVal
   */
  @Watch("breakdown")
  public setAmount(newVal: BatchTransfer.PaymentMethodsBreakdown) {
    this.model = cloneDeep(newVal);
    if (this.model.outstanding === 0) {
      this.editingModal.applied = this.model.outstanding;
    } else if (this.model.outstanding > 0) {
      this.editingModal.applied =
        this.model.outstanding ||
        parseFloat(
          currencyFilter(newVal.initial)
            .replace(/\$ | \s/g, "")
            .replace(",", "")
        );
    }
  }

  /**
   * Labels payment breakdown
   * @param {string} type
   * @returns {string} Translated label.
   */
  public label(type: string) {
    return this.$t(`batch_transfer_manager_module.${type}`);
  }

  /**
   * Emits change event.
   */
  public onChanges() {
    const clone = cloneDeep(this.values);
    clone.outstanding = this.outstanding;
    this.$emit("change", clone);
  }

  /**
   * Getter values
   */
  public get values(): BatchTransfer.PaymentMethodsBreakdown {
    this.model.initial = Number(this.breakdown.initial);
    this.model.payment_and_methods.forEach((payment, index, payments) => {
      if (index === 0) {
        payment.remaining = this.model.initial - Number(payment.applied);
      } else {
        payment.remaining =
          Number(payments[index - 1].remaining) - Number(payment.applied);
      }
    });
    return this.model;
  }

  /**
   * Removes payment
   * @param {number} index
   */
  public removePayment(index: number) {
    const payments = cloneDeep(this.model.payment_and_methods);
    payments.splice(index, 1);
    this.model.payment_and_methods = payments;
    this.onChanges();
  }

  /**
   * Saves payment breakdown
   */
  public async save() {
    if (await this.$validator.validateAll("payment")) {
      const payment = cloneDeep(this.editingModal);
      payment.name = this.methods.find(
        method => method.id === this.editingModal.type
      )!.name;
      this.model.payment_and_methods.push(payment);
      this.onChanges();
      this.editingModal = cloneDeep(this.defaultEditingModal);
    }
  }

  /**
   * Gets total applied
   */
  public get totalApplied() {
    return reduce(
      this.values.payment_and_methods,
      (sum: number, method: BatchTransfer.PaymentMethod) => {
        return Number(sum) + Number(method.applied);
      },
      0
    );
  }

  public get canAddPayments(): boolean {
    return !!(
      (this.editingTransfer && !this.model.payment_and_methods.length) ||
      !this.editingTransfer
    );
  }

  /**
   * Gets outstanding
   */
  public get outstanding() {
    const outstanding = Number(this.values.initial) - Number(this.totalApplied);
    this.model.outstanding = outstanding;
    return outstanding < 0.0099 ? 0.0 : outstanding;
  }
  protected created() {
    this.$validator.extend("lessThanOutstanding", (value: number) => {
      return Number(value) > 0 && Number(value) <= this.outstanding;
    });
  }
  protected mounted() {
    this.model.initial = cloneDeep(this.breakdown.initial);
    EventBus.$on("modelReseted", () => {
      this.model.payment_and_methods = [];
    });
    this.validMethods = this.methods.filter(m => m.type !== "STORE_CREDIT");
  }
}
