import { BatchTransfer } from "@/interfaces/batchTransferManager";
import { Validator } from "vee-validate";
import Component from "vue-class-component";
import { Inject, Provide, Vue } from "vue-property-decorator";

@Component({})
export default class TransferValidators extends Vue {
  @Inject("validator") public $validator!: Validator;
  @Provide() public validator: Validator = this.$validator;
  protected created() {
    this.$validator.extend("valid_product", this.validProduct);
    this.$validator.extend("valid_discount", this.validDiscount);
    this.$validator.extend("valid_fee", this.validFee);
    this.$validator.extend("valid_purchase_order", this.validPurchaseOrder);
  }

  /**
   * Valids product
   * @param {Object} product
   * @param {number} product.id
   * @returns {boolean} Wether the product id exists
   */
  protected validProduct(product: { id: number }) {
    return product.id > 0;
  }

  /**
   * Valids discount
   * @param {BatchTransfer.TransferFlyingDiscount} discount
   * @returns {boolean} Wether the discount is valid
   */
  protected validDiscount(discount: BatchTransfer.TransferFlyingDiscount) {
    return (
      !!discount.amount && !(discount.description === "" || !discount.type)
    );
  }

  /**
   * Valids fee
   * @param {BatchTransfer.TransferFlyingFee} fee
   * @returns {boolean} Wether the fee is valid
   */
  protected validFee(fee: BatchTransfer.TransferFlyingFee) {
    return !!fee.amount && !(fee.description === "" || !fee.type);
  }

  /**
   * Valids purchase order
   * @param {BatchTransfer.PurchaseOrder} order
   * @returns {boolean} Wether the Purchase Order is valid
   */
  protected validPurchaseOrder(order: BatchTransfer.PurchaseOrder) {
    let valid = true;
    valid = valid && !!order.items.length;
    return valid;
  }
}
